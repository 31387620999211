<template>
  <div class="commande" :data-status="commande.bo_status" v-if="commande">
    <div class="sh">

      <div class="line plan" v-if="commande.plan">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z" /></svg>
        <div class="t">Planifiée pour {{ commande.plan.hour }}</div>
      </div>
      
      <div class="top">
        <div class="left">
          <div class="name capitalize" v-if="commande.nom">{{ commande.prenom }} {{ commande.nom[0] }}</div>
          <div class="tel">{{ commande.user_phone }}</div>
        </div>
        <div class="right">
          <div class="date">
            <span class="hour">{{ commande.at | formatday('ddd HH:mm') }}</span>
            <span class="at">Ref: {{ commande.id }}</span>
          </div>
          <a :href="'tel:' + commande.user_phone" class="btn_tel">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" /></svg>
          </a>
          <div class="button" v-if="!info_open">Infos</div>
        </div>
      </div>
    
      <div class="etat">
        <span></span>
      </div>





      <div class="details" v-if="info_etat === true">
        <router-link tag="div" :to="{ name: 'produit', params: { product_id: d.data.id, name: d.data.name, view: true } }" class="article" v-for="(d, k) in commande.articles.articles" :key="k">
          <div class="line">
            <div class="name">
              <div class="img"><el-image :src="$img_path + d.data.img_key" fit="contain"></el-image></div>
              <div class="nb">{{ d.nb }}</div>
              <div class="n">{{ d.data.name }}</div>
            </div>
            <div class="price">{{ d.data.final_price + d.options.total | formatNumberdecimal }}€</div>
          </div>
          <div class="options" v-if="d.options.options.length">
            <div class="o" v-for="(o, k1) in d.options.options" :key="k1">
              <i class="plus el-icon-arrow-right"></i>
              {{ o.name }}
            </div>
          </div>
        </router-link>
        <div class="total">
  <!--        <div class="line"><span class="n">Sous Total</span> <span class="p">{{ data.price | formatNumberdecimal }}€</span></div>-->
  <!--        <div class="line"><span class="n">TVA</span> <span class="p">0.45€</span></div>-->
          <div v-if="commande.livraison.price" class="line livraison_price"><span class="n">Livraison</span> <span class="p">{{ commande.livraison.price | formatNumberdecimal }}€</span></div>
          <div class="line bold"><span class="n">Total</span> <span class="p">{{ commande.price | formatNumberdecimal }}€</span></div>
          
        </div>
        
        
        <div class="line couverts" v-if="commande.couverts">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 24 24"><path d="M11,9H9V2H7V9H5V2H3V9C3,11.12 4.66,12.84 6.75,12.97V22H9.25V12.97C11.34,12.84 13,11.12 13,9V2H11V9M16,6V14H18.5V22H21V2C18.24,2 16,4.24 16,6Z" /></svg>
          <div class="t">Couverts demandés</div>
        </div>
        
        <div class="line note" v-if="commande.note">
          <div class="t">Note</div>
          {{ commande.note }}
        </div>
        
        
        

        <div class="line retrait capitalize" v-if="commande.livraison && commande.livraison.type && commande.livraison.type === 'retrait'">
          <div class="t">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M7 16.462l1.526-.723c1.792-.81 2.851-.344 4.349.232 1.716.661 2.365.883 3.077 1.164 1.278.506.688 2.177-.592 1.838-.778-.206-2.812-.795-3.38-.931-.64-.154-.93.602-.323.818 1.106.393 2.663.79 3.494 1.007.831.218 1.295-.145 1.881-.611.906-.72 2.968-2.909 2.968-2.909.842-.799 1.991-.135 1.991.72 0 .23-.083.474-.276.707-2.328 2.793-3.06 3.642-4.568 5.226-.623.655-1.342.974-2.204.974-.442 0-.922-.084-1.443-.25-1.825-.581-4.172-1.313-6.5-1.6v-5.662zm-1 6.538h-4v-8h4v8zm15-11.497l-6.5 3.468v-7.215l6.5-3.345v7.092zm-7.5-3.771v7.216l-6.458-3.445v-7.133l6.458 3.362zm-3.408-5.589l6.526 3.398-2.596 1.336-6.451-3.359 2.521-1.375zm10.381 1.415l-2.766 1.423-6.558-3.415 2.872-1.566 6.452 3.558z"/></svg>
            <span>{{ commande.livraison.type }}</span>
          </div>
          <div class="a">
            {{ commande.livraison.day }} {{ commande.livraison.hour }}
          </div>
        </div>
        <div class="line retrait livraison capitalize" v-if="commande.livraison && commande.livraison.type && commande.livraison.type === 'livraison'">
          
          <div class="left">
            <div class="t">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M11.74 13.36L14.14 7.71L13.06 5.5H10.5V4H14L14.73 5.5H21.75L20.75 9H16.44L17.11 10.37C17.69 10.13 18.33 10 19 10C21.76 10 24 12.24 24 15C24 17.76 21.76 20 19 20C16.24 20 14 17.76 14 15C14 13.45 14.71 12.06 15.82 11.15L15 9.5L12.25 16H9.9C9.44 18.28 7.42 20 5 20C2.24 20 0 17.76 0 15C0 12.24 2.24 10 5 10C7.59 10 9.72 11.97 10 14.5H10.58L8.3 9H7.5C7.09 9 6.75 8.66 6.75 8.25C6.75 7.84 7.09 7.5 7.5 7.5H10.25C10.66 7.5 11 7.84 11 8.25C11 8.66 10.66 9 10.25 9H9.97L11.74 13.36M5 11.5C3.07 11.5 1.5 13.07 1.5 15C1.5 16.93 3.07 18.5 5 18.5C6.59 18.5 7.93 17.45 8.36 16H4V14.5H8.47C8.22 12.8 6.76 11.5 5 11.5M19 11.5C18.57 11.5 18.15 11.58 17.77 11.72L19.7 15.68L18.35 16.34L16.5 12.55C15.88 13.18 15.5 14.05 15.5 15C15.5 16.93 17.07 18.5 19 18.5C20.93 18.5 22.5 16.93 22.5 15C22.5 13.07 20.93 11.5 19 11.5Z" /></svg>
              <span>{{ commande.livraison.type }}</span>
            </div>
            <div class="a">
                
              {{ commande.livraison.adresse.adresse.streetNumber }} {{ commande.livraison.adresse.adresse.streetName }} {{ commande.livraison.adresse.adresse.zipcode }} {{ commande.livraison.adresse.adresse.city }}
                
              <span v-if="commande.livraison.adresse.batiment"><br>Bâtiment : {{ commande.livraison.adresse.batiment }}<br></span>
              <span v-if="commande.livraison.adresse.entreprise"><br>Entreprise : {{ commande.livraison.adresse.entreprise }}<br></span>
              <span v-if="commande.livraison.adresse.instructions"><br>Instructions : {{ commande.livraison.adresse.instructions }}</span>
            </div>
          </div>
          <div class="right">
            <a target="_blank" :href="convert_link_map(commande.livraison.adresse.adresse)">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M14,14.5V12H10V15H8V11A1,1 0 0,1 9,10H14V7.5L17.5,11M21.71,11.29L12.71,2.29H12.7C12.31,1.9 11.68,1.9 11.29,2.29L2.29,11.29C1.9,11.68 1.9,12.32 2.29,12.71L11.29,21.71C11.68,22.09 12.31,22.1 12.71,21.71L21.71,12.71C22.1,12.32 22.1,11.68 21.71,11.29Z" /></svg>
            </a>
          </div>
        </div>
        
        
        <div class="boutons" v-if="status === 'waiting'">

          <el-dropdown trigger="click" @command="remboursement_select">
            <div class="button">Refuser</div>


            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Doublon">Doublon</el-dropdown-item>
              <el-dropdown-item command="Frauduleux">Frauduleux</el-dropdown-item>
              <el-dropdown-item command="Demandé par le client">Demandé par le client</el-dropdown-item>
              <el-dropdown-item command="Le produit n'est plus en stock">Le produit n'est plus en stock</el-dropdown-item>
              <el-dropdown-item command="Autre">Autre</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="button black" @click="confirm">En préparation</div>
        </div>
        
        <div class="boutons" v-if="status === 'processing'">
          <el-dropdown trigger="click" @command="remboursement_select">
            <div class="button">Annuler</div>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Doublon">Doublon</el-dropdown-item>
              <el-dropdown-item command="Frauduleux">Frauduleux</el-dropdown-item>
              <el-dropdown-item command="Demandé par le client">Demandé par le client</el-dropdown-item>
              <el-dropdown-item command="Le produit n'est plus en stock">Le produit n'est plus en stock</el-dropdown-item>
              <el-dropdown-item command="Autre">Autre</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>


          <el-dropdown v-if="commande.livraison && commande.livraison.type === 'livraison'" trigger="click" @command="livreur_select">
            <div class="button black">Commande prête</div>
            
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="n in livreurs" :key="n.id" :command="n.id">Envoyer à {{ n.name }}</el-dropdown-item>
              <template v-if="livreurs.length"> 
                <el-dropdown-item :command="false">Ne pas sélectionner de livreur</el-dropdown-item>
              </template>
              <el-dropdown-item v-else :command="false">Commande prête</el-dropdown-item>
              <el-dropdown-item command="alreadyDelivered">Commande déjà livrée</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          
          
          <el-dropdown v-else trigger="click" @command="livreur_select">
            <div class="button black">Commande prête</div>
            
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="false">Commande prête</el-dropdown-item>
              <el-dropdown-item command="alreadyDelivered">Commande déjà retirée</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          
<!--          <div v-else @click="ready" class="button black">Commande prête</div>-->
          
        </div>

        <div class="boutons" v-if="status === 'sent'">
          <el-dropdown v-if="!livreur" trigger="click" @command="remboursement_select">
            <div class="button">Annuler</div>


            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Doublon">Doublon</el-dropdown-item>
              <el-dropdown-item command="Frauduleux">Frauduleux</el-dropdown-item>
              <el-dropdown-item command="Demandé par le client">Demandé par le client</el-dropdown-item>
              <el-dropdown-item command="Le produit n'est plus en stock">Le produit n'est plus en stock</el-dropdown-item>
              <el-dropdown-item command="Autre">Autre</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="button black" v-if="commande.livraison && commande.livraison.type === 'retrait'" @click="pick">Commande retirée</div>
          <div class="button black" v-else @click="pick">Commande livrée</div>
        </div>
        
        <div class="remboursement" v-if="remboursement_type">
          <el-input v-model="remboursement_details" type="textarea" placeholder="Ajoutez des détails sur ce remboursement"></el-input>
          <button_plus @success="remboursement_start" color="stripe" txt="Rembourser" txt_load="Remboursement" class="bouton"></button_plus>
        </div>
        
        
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commande",
  data() {
    return {
      info_etat: false,
      commande: null,

      remboursement_type: null,
      remboursement_details: null,
      
      livreurs: []
    }
  },
  props: {
    status: {
      type: String
    },
    info_open: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    livreur: {
      type: String
    }
  },
  created() {
    this.info_etat = this.info_open;

    // if(this.$store.state.user.user.boutique)
      this.get_card()
      this.get_livreurs()
  },
  methods: {
    get_livreurs() {
      this.$http.get('/livreur/list_livreurs/' + this.$store.state.user.user.boutique).then((livreurs) => {
        this.livreurs = livreurs.data
      })
    },
    convert_link_map(link) {
      if(link.streetNumber)
       return `http://maps.apple.com/?daddr=${link.streetNumber}+${link.streetName}+${link.zipcode}+${link.city}`
      else
       return `http://maps.apple.com/?daddr=${link.streetName}+${link.zipcode}+${link.city}`
    },
    get_card() {
      if(!this.livreur) {
        this.$http.get('/bo/commande/' + this.data.id).then((commande) => {
          this.commande = commande.data
        })
      }
      else {
        this.$http.get('/livreur/commande/' + this.livreur + '/' + this.data.id).then((commande) => {
          this.commande = commande.data
        })
      }
    },
    confirm() {
      if(confirm('Êtes vous sûrs ? Le client va recevoir un SMS.')) {
        this.$http.post('/bo/commande/confirm', {
          commande_id: this.commande.id
        }).then(() => {
          // this.$store.dispatch("boutique_bo/sync_commandes");
        })
      }
    },
    ready(livreur_id = false) {
      // if(confirm('Êtes vous sûrs ? Le client va recevoir un SMS.')) {
        this.$http.post('/bo/commande/ready', {
          commande_id: this.commande.id
        }).then(() => {
          // this.$store.dispatch("boutique_bo/sync_commandes");
          if (livreur_id) this.set_livreur(livreur_id)
        })
      // }
    },
    readyAlreadyDelivered() {
      if(confirm('Êtes vous sûrs ?')) {
        this.$http.post('/bo/commande/ready', {
          commande_id: this.commande.id,
          alreadyDelivered: true
        }).then(() => {
          // this.$store.dispatch("boutique_bo/sync_commandes");
        })
      }
    },
    set_livreur(livreur_id) {
      this.$http.post('/bo/commande/set_livreur', {
        commande_id: this.commande.id,
        livreur_id: livreur_id
      }).then(() => {
        console.log('livreur_id_send')
      })
    },
    pick() {
      if(confirm('Êtes vous sûrs ?')) {
        if(!this.livreur) {
          this.$http.post('/bo/commande/pick', {
            commande_id: this.commande.id
          }).then(() => {
            // this.$store.dispatch("boutique_bo/sync_commandes");
          })
        }
        else {
          this.$http.post('/livreur/pick', {
            boutique_id: this.commande.boutique_id, commande_id: this.commande.id, livreur_key: this.livreur
          }).then(() => {
            // this.$store.dispatch("boutique_bo/sync_commandes");
          })
        }
      }
    },
    remboursement_select(n) {
      this.remboursement_type = n
    },
    livreur_select(n) {
      if (n === 'alreadyDelivered') {
        this.readyAlreadyDelivered()
      }
      else if(confirm('Êtes vous sûrs ? Le client va recevoir un SMS.')) {
        if (n) this.ready(n)
        else this.ready()
      }
    },
    remboursement_start() {
      this.$http.post('/bo/commande/remboursement', {
        type: this.remboursement_type,
        details: this.remboursement_details,
        commande_id: this.commande.id
      }).then(() => {
        this.$notify({
          title: 'Remboursement effectué', offset: this.$notifyOffest, type: 'success',
        });
        this.$store.dispatch("boutique_bo/sync_commandes");
      })
    }
  }
}
</script>

<style scoped>


.commande { border-radius: 2px; overflow-x: hidden; padding-bottom: 4px; }
.commande .top { font-size: 16px; display: flex; justify-content: space-between; background: #fff; height: 60px; align-items: center;
  padding-left: 14px;
}
.commande .sh { box-shadow: 0 1px 0px 2px rgb(144 144 144 / 5%), 0 1px 12px 0px rgb(66 64 64 / 10%) }
.commande .top .left { display: flex; flex-direction: column; justify-content: center; }
.commande .top .left .name { font-weight:  bold }
.commande .top .left .name .tel { font-weight: 400; font-size: 12px; margin-left: 6px; position: relative; top: -1px; border: none }
.commande .top .left .ref { font-size: 12px; color: var(--black-sub) }
.commande .top .left .tel { font-size: 15px; color: var(--black-sub) }
.commande .top .right .date { display: flex; flex-direction: column; margin-right: 12px; text-align: right }
.commande .top .right .date .hour { font-weight: bold }
.commande .top .right .date .at { font-size: 12px; color: var(--black-sub) }
.commande .top .right { display: flex; align-items: center }
.commande .top .right .btn_tel { height: 40px; width: 40px; background: var(--gris); display: flex; align-items: center; justify-content: center; border-radius: 10%;
  margin-right: 10px; fill: var(--black-main); border: none!important;
}
.commande .top .right .button { border: 1px solid #333; height: 34px; border-radius: 4px; display: flex; align-items: center; margin-right: 10px; justify-content: center; padding: 0 8px; cursor: pointer; }


.commande .etat { height: 24px; background: var(--boRed); display: flex; align-items: center; padding-left: 14px; font-size: 16px; font-weight: bold; color: #fff; position: relative; }
.commande .etat span { position: relative; z-index: 2; padding-top: 3px; }

.commande[data-status=waiting] .etat span:after { content: 'Commande à confirmer' }
.commande[data-status=waiting] .etat { background: var(--boRed) }

.commande[data-status=processing] .etat span:after { content: 'Commande en préparation' }
.commande[data-status=processing] .etat { background: var(--boTurq) }

.commande[data-status=sent] .etat span:after { content: 'Commande en livraison / à retirer' }
.commande[data-status=sent] .etat { background: var(--boVert) }

.commande .etat:after { content: ''; position: absolute; right: 0; bottom: 0; left: 0; top: 0; background-size: 20px 20px; animation: bar 10s infinite linear;
  background-image: -webkit-linear-gradient(135deg, transparent,
  transparent 25%, hsla(0,0%,0%,.05) 25%,
  hsla(0,0%,0%,.05) 50%, transparent 50%,
  transparent 75%, hsla(0,0%,0%,.05) 75%,
  hsla(0,0%,0%,.05));
}

.commande .details { background: #fff; font-size: 16px }
.commande .details .article { display: flex; min-height: 50px; flex-direction: column; justify-content: center; padding: 0 16px 0 8px; border-bottom: 1px solid #eee; cursor: pointer; }
.commande .details .article .line { display: flex; align-items: center; height: 52px; }
.commande .details .article .options { padding-left: 67px; padding-bottom: 14px; }
.commande .details .article .options .o { min-height: 28px; display: flex; align-items: center; font-size: 15px; }
.commande .details .article .options .o .plus { background: var(--gris-button); border-radius: 100%; padding: 2px; color: #111; font-size: 12px; margin-right: 5px; }
.commande .details .article .nb { font-size: 20px; font-weight: bold; position: relative; top: -2px; font-family: "Product Sans"; padding-right: 8px; padding-left: 4px; }
.commande .details .article .name { width: calc(100% - 55px); display: flex; align-items: center; }
.commande .details .article .name .n { font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.commande .details .article .name .img { width: 36px; min-width: 36px; height: 36px; background: #eee; margin-right: 8px; border-radius: 2px; overflow: hidden }
.commande .details .article .price { width: 60px; text-align: right; color: var(--black-sub) }

.commande .details .total { display: flex; flex-direction: column; align-items: flex-end; padding: 20px 16px 4px 0; }
.commande .details .total .line { height: 30px; }
.commande .details .total .line .n { width: 80px; display: inline-block }
.commande .details .total .line .p { min-width: 90px; display: inline-block; text-align: right }
.commande .details .total .line.bold { font-weight: bold; }
.commande .details .total .line.livraison_price { height: 24px; }
.commande .details .total .line.livraison_price .p { color: var(--black-sub); }

.commande .details .line.retrait { background: var(--gris); color: var(--black-main); fill: var(--black-main); padding: 8px 14px; border-radius: 4px; display: flex;
  min-height: 30px; height: inherit; font-weight: 500; font-size: 15px; flex-direction: column; width: 100%;
}
.commande .details .line.retrait .t { display: flex; align-items: center; margin-bottom: 4px; color: var(--boTurq); font-size: 16px  }
.commande .details .line.retrait span { margin-right: 8px; font-weight: bold; padding-top: 2px; }
.commande .details .line.retrait svg { margin-right: 6px; transform: scale(.8); fill: var(--boTurq) }
.commande .details .line.retrait.livraison .t { color: var(--boVert); }
.commande .details .line.retrait.livraison .t svg { fill: var(--boVert) }
.commande .details .line.retrait .a span { font-weight: 500; color: var(--black-sub) }
.commande .details .line.retrait.livraison { display: flex; justify-content: space-between; flex-direction: row; align-items: center; padding-right: 10px; }
.commande .details .line.retrait.livraison .right { flex: 0 0 auto; margin-left: 16px; }
.commande .details .line.retrait.livraison .right a { width: 40px; height: 40px; background: #fff; border: none!important; display: flex; align-items: center; justify-content: center; border-radius: 10% }
.commande .details .line.retrait.livraison .right a svg { fill: var(--black-main); margin-right: 0; }


.commande .line.plan { background: #444; display: flex; align-items: center; padding: 8px 14px; color: #fff; fill: #fff; font-size: 16px; position: relative; }
.commande .line.plan svg { margin-right: 6px; }
.commande .line.plan:after { content: ''; position: absolute; right: 0; bottom: 0; left: 0; top: 0; background-size: 20px 20px; animation: bar 10s infinite linear;
  background-image: -webkit-linear-gradient(135deg, transparent,
  transparent 25%, hsla(0,100%,100%,.06) 25%,
  hsla(0,100%,100%,.06) 50%, transparent 50%,
  transparent 75%, hsla(0,100%,100%,.06) 75%,
  hsla(0,100%,100%,.06));
}


.commande .details .line.note { padding: 16px 14px; background: #f6f6f9; white-space: pre-line }
.commande .details .line.note .t { font-weight: 700; margin-bottom: 2px; color: var(--black-sub) 
}
.commande .details .line.couverts { padding: 16px 14px; background: #f6f6f9; white-space: pre-line; justify-content: flex-start; display: flex; align-items: center; color: var(--black-sub); fill: var(--black-sub);
  border-bottom: 1px solid #fff;
}
.commande .details .line.couverts svg { margin-right: 8px; }

.commande .details .boutons { height: 60px; border-top: 1px solid #eee; display: flex; align-items: center; justify-content: stretch; padding: 0 9px;
  box-shadow: 0 -4px 6px rgba(200,200,200,.2);
}
.commande .details .boutons .button { background: #ddd; height: 40px; display: flex; align-items: center; justify-content: center; padding: 0 8px; border-radius: 2px;
  width: 100%; margin-right: 6px; cursor: pointer; border-radius: 2px;
}
.commande .details .boutons .el-dropdown { width: 100%; margin-right: 6px; }
.commande .details .boutons .button:last-child { margin-right: 0; }

.commande .details .boutons .button.black { background: var(--orange); color: #fff; }

.commande .remboursement { display: flex; padding: 14px 16px; border-top: 1px solid #eee; }
.commande .remboursement .bouton { margin-left: 16px; }

@keyframes bar {
  from{ background-position: 0 }
  to{ background-position: 100% }

}


@media only screen and (min-width: 800px) {
  .bo nav { bottom: 30%; top: 20px; right: inherit; flex-direction: column }
  .bo .commandes { flex-direction: column }

  .bo .commandes .elem { border-bottom: 1px solid #111; height: calc(100% / 3); margin: 0 0 20px 0; }
}


</style>