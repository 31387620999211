<template>
  <div class="scrollx">
    <title_cat>Commandes à confirmer</title_cat>

    <div class="tuto" v-if="$parent.commandes && $parent.commandes.waiting && $parent.commandes.waiting.length === 0">
      <img src="../../assets/img/illu/two/waiting.svg" alt="">
      <span>
        Lors du passage d'une commande par l'un de vos clients, une fois le paiement validé, vous devrez confirmer la commande sur cette page.
      </span>
    </div>
    
    <div class="commandes_list" v-else-if="$parent.commandes">
      <div class="commandes_cont">
      <commande class="commande" :info_open="true" v-for="n in $parent.commandes.waiting" :key="n.id" :data="n" status="waiting"></commande>
      </div>
    </div>
    
  </div>
</template>

<script>



import commande from '@/components/bo/commande.vue'
export default {
  name: "commandes_enattente",
  components: { commande },
  created() {
  }
}

</script>